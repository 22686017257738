import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Pen: FC<IconSvgProps> = ({ clss }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" className={clss}>
            <g>
                <path d="M 21.464844 1.402344 L 20.597656 0.535156 C 20.253906 0.191406 19.792969 0 19.308594 0 C 18.820312 0 18.359375 0.191406 18.015625 0.535156 L 17.359375 1.191406 L 17.273438 1.105469 C 17.101562 0.933594 16.824219 0.933594 16.652344 1.105469 L 2.722656 15.035156 C 2.683594 15.074219 2.652344 15.125 2.628906 15.175781 L 1.355469 18.230469 L 0.0351562 21.394531 C -0.0351562 21.558594 0.00390625 21.746094 0.128906 21.871094 C 0.210938 21.957031 0.324219 22 0.4375 22 C 0.496094 22 0.554688 21.988281 0.609375 21.964844 L 3.769531 20.648438 L 6.824219 19.371094 C 6.878906 19.347656 6.925781 19.316406 6.964844 19.277344 L 20.894531 5.347656 C 20.976562 5.265625 21.023438 5.152344 21.023438 5.039062 C 21.023438 4.921875 20.976562 4.808594 20.894531 4.726562 L 20.808594 4.640625 L 21.464844 3.984375 C 22.175781 3.273438 22.175781 2.113281 21.464844 1.402344 Z M 1.253906 20.746094 L 1.910156 19.171875 L 2.828125 20.089844 Z M 3.703125 19.726562 L 2.277344 18.296875 L 3.1875 16.117188 L 5.882812 18.816406 Z M 6.65625 18.347656 L 3.652344 15.34375 L 15.566406 3.433594 L 18.566406 6.433594 Z M 19.1875 5.8125 L 16.1875 2.8125 L 16.964844 2.035156 L 19.964844 5.039062 Z M 20.847656 3.363281 L 20.195312 4.015625 L 17.984375 1.804688 L 18.636719 1.15625 C 18.816406 0.976562 19.054688 0.875 19.308594 0.875 C 19.558594 0.875 19.800781 0.976562 19.976562 1.15625 L 20.847656 2.023438 C 21.214844 2.390625 21.214844 2.996094 20.847656 3.363281 Z M 20.847656 3.363281 " />
                <path d="M 11.1875 8.738281 L 4.847656 15.078125 C 4.675781 15.25 4.675781 15.527344 4.847656 15.695312 C 4.933594 15.78125 5.046875 15.824219 5.15625 15.824219 C 5.269531 15.824219 5.382812 15.78125 5.46875 15.695312 L 11.804688 9.359375 C 11.976562 9.1875 11.976562 8.910156 11.804688 8.738281 C 11.636719 8.566406 11.359375 8.566406 11.1875 8.738281 Z M 11.1875 8.738281 " />
                <path d="M 13.160156 6.765625 L 12.359375 7.566406 C 12.1875 7.734375 12.1875 8.011719 12.359375 8.183594 C 12.445312 8.269531 12.558594 8.3125 12.671875 8.3125 C 12.78125 8.3125 12.894531 8.269531 12.980469 8.183594 L 13.777344 7.386719 C 13.949219 7.214844 13.949219 6.9375 13.777344 6.765625 C 13.605469 6.59375 13.328125 6.59375 13.160156 6.765625 Z M 13.160156 6.765625 " />
            </g>
        </svg>
    );
};

export default Pen;
